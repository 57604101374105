import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";

import LogIn from "../Components/Auth/Login";

function LogInRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<LogIn />} />
    </Routes>
  );
}

export default LogInRouter;
