import React from 'react';

const Register = () => {
  return (
    <div className="content">
      <h2>Register</h2>
      
    </div>
  );
};

export default Register;
