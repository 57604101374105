import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Success from "../Components/Success";

function SuccessRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Success />} />
    </Routes>
  );
}

export default SuccessRouter;
