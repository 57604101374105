import {Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import { paths } from "../utils/routes";
import RegisterRouter from './Register';
import PaymentRouter from './Payment';
import ConfirmationRouter from './Confirmation';
import LogInRouter from './LogIn';
import SuccessRouter from "./Success";


function Base(props) {
  // const location = useLocation();

  return (
    <div className="">
          <Routes>
            <Route path={paths.base} element={<Home />} />
            <Route path={paths.payment} element={<PaymentRouter />} />
            <Route path={paths.success} element={<SuccessRouter />} />
            <Route path={paths.confirmation} element={<ConfirmationRouter />} />
            <Route path={paths.login} element={<LogInRouter />} />
            <Route path={paths.register} element={<RegisterRouter />} />
          </Routes>
    </div>
  );
}
export default Base;

