// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { RiArrowDownSFill } from "react-icons/ri";
// import { FaSpinner } from "react-icons/fa";
// import Loader from "./Loader"; // Import Loader component
// import '../css/form.css'; 

// const MeterForm = () => {
//   const [formData, setFormData] = useState({
//     paymentType: "Electricity",
//     userLocation: "",
//     accountType: "Prepaid",
//     MeterNumber: "", // Will be customerAccountNo for Postpaid
//     phoneNumber: "",
//     email: "",
//     amount: "", // Will be paymentChannelAmount for Postpaid
//   });

//   const [electricityAmount, setElectricityAmount] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [iframeSrc, setIframeSrc] = useState("");
//   const [submitted, setSubmitted] = useState(false); 
//   const [showLoader, setShowLoader] = useState(false); // State to show/hide the loader

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleAmountChange = (e) => {
//     const { value } = e.target;
//     const amount = parseFloat(value);
//     setElectricityAmount(amount.toString());
//   };
 

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setSubmitted(true);
//     setLoading(true);

//     // Adjust POST URL based on account type
//     const postUrl =
//       formData.accountType === "Prepaid"
//         ? "https://kad-api.fyber.site/payment/init/online-pay-prepaid/"
//         : "https://kad-api.fyber.site/payment/init/online-pay-postpaid/";

//     // Adjust payload based on account type
//     const payload = formData.accountType === "Prepaid"
//       ? {
//           agentId: "1",
//           meterNumber: formData.MeterNumber,
//           amount: electricityAmount,
//           locationOfPayment: formData.userLocation,
//           notes: "Payment for electricity",
//           telephoneNumber: formData.phoneNumber,
//           laitude: "1",
//           longitude: "1",
//           payment_type: "online",
//         }
//       : {
//           agentId: "1",
//           customerAccountNo: formData.MeterNumber, // Same input as MeterNumber
//           paymentChannelAmount: electricityAmount, // Same input as amount
//           locationOfPayment: formData.userLocation,
//           notes: "Payment for electricity",
//           telephoneNumber: formData.phoneNumber,
//           laitude: "1",
//           longitude: "1",
//           payment_type: "online",
//         };

//     try {
//       const response = await fetch(postUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(payload),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         if (data.data && data.data.checkoutUrl) {
//           setIframeSrc(data.data.checkoutUrl);
//           setShowModal(true);
//           const reference = data.data.reference;
//           await pollForPaymentStatus(reference); // Start polling
//         } else {
//           console.error("checkoutUrl not found in the response");
//         }
//       } else {
//         const errorText = await response.text();
//         console.error("Transaction Failed:", errorText);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const pollForPaymentStatus = async (reference) => {
//     const checkInterval = 10000;
//     const maxAttempts = 12;
//     let attempts = 0;

//     while (attempts < maxAttempts) {
//       const finalizeResponse = await fetch(
//         `https://kad-api.fyber.site/payment/finalize/online-pay/${reference}/${formData.MeterNumber}/`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (finalizeResponse.ok) {
//         const finalizeData = await finalizeResponse.json();

//         // Show loader and wait for 5 seconds before navigation
//         setShowLoader(true); // Show loader
//         setTimeout(() => {
//           navigate("/Success", { state: { finalizeData, formData } }); // Navigate after 5 seconds
//         }, 5000); // Delay of 5 seconds
//         break;
//       } else {
//         const errorText = await finalizeResponse.text();
//         if (errorText.includes("Payment Still Processing!")) {
//           attempts++;
//           await new Promise((resolve) => setTimeout(resolve, checkInterval));
//         } else {
//           break;
//         }
//       }
//     }

//     if (attempts >= maxAttempts) {
//       console.error("Max attempts reached for finalizing payment.");
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setIframeSrc("");
//     setSubmitted(false);
//   };
 

//   return (
//     <div className="form-container paddng_form">
//       {showLoader && <Loader />} {/* Show loader 5 seconds before navigating */}

//       <form className="payment-form" onSubmit={handleSubmit}>
//         <h2>Payment Form</h2>
//         <div className="form-input">
//           <div className="form-group">
//             <label htmlFor="accountType">Account Type</label>
//             <div className="select-wrapper">
//               <select
//                 id="accountType"
//                 name="accountType"
//                 value={formData.accountType}
//                 onChange={handleChange}
//                 disabled={loading} // Disable field during loading
//               >
//                 <option value="Prepaid">Prepaid</option>
//                 <option value="Postpaid">Postpaid</option>
//               </select>
//               <RiArrowDownSFill className="dropdownarrow" />
//             </div>
//           </div>
//           <div className="form-group">
//             <label htmlFor="userLocation">Location</label>
//             <div className="select-wrapper">
//               <select
//                 id="userLocation"
//                 name="userLocation"
//                 value={formData.userLocation}
//                 onChange={handleChange}
//                 required
//                 disabled={loading} // Disable field during loading
//               >
//                 <option value="" disabled>Select Location</option>
//                 <option value="Kaduna">Kaduna</option>
//                 <option value="Kebbi">Kebbi</option>
//                 <option value="Sokoto">Sokoto</option>
//                 <option value="Zamfara">Zamfara</option>
//               </select>
//               <RiArrowDownSFill className="dropdownarrow" />
//             </div>
//           </div>
//           <div className="form-group">
//             <label htmlFor="MeterNumber">
//               {formData.accountType === "Prepaid" ? "Meter Number" : "Customer Account Number"}
//             </label>
//             <input
//               type="text"
//               id="MeterNumber"
//               name="MeterNumber"
//               value={formData.MeterNumber}
//               onChange={handleChange}
//               placeholder={`Enter ${formData.accountType === "Prepaid" ? "meter number" : "customer account number"} here`}
//               required
//               disabled={loading} // Disable field during loading
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="email">Email</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               placeholder="Email address"
//               required
//               disabled={loading} // Disable field during loading
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number</label>
//             <input
//               type="text"
//               id="phoneNumber"
//               name="phoneNumber"
//               value={formData.phoneNumber}
//               onChange={handleChange}
//               placeholder="Enter phone number"
//               required
//               disabled={loading} // Disable field during loading
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="electricityAmount">
//               {formData.accountType === "Prepaid" ? "Electricity Amount (₦)" : "Payment Channel Amount (₦)"}
//             </label>
//             <input
//               type="number"
//               id="electricityAmount"
//               name="electricityAmount"
//               value={electricityAmount}
//               onChange={handleAmountChange}
//               min="0"
//               required
//               disabled={loading} // Disable field during loading
//             />
//           </div>
//           <div className="button-container">
//             <button type="submit" className="submit" disabled={loading}>
//               {loading ? (
//                 <>
//                   <FaSpinner className="spinner rotating" /> {/* Display Spinner */}
//                   Processing
//                 </>
//               ) : (
//                 "Buy Now"
//               )}
//             </button>
//           </div>
//         </div>
//       </form>

//       {showModal && (
//         <div className="modal-overlay">
//           <div className="modal">
//             <button className="close-button" onClick={closeModal}>X</button>

//             <iframe
//               src={iframeSrc}
//               title="Payment Checkout"
//               style={{ width: "100%", height: "500px", border: "none" }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MeterForm;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiArrowDownSFill } from "react-icons/ri";
import { FaSpinner } from "react-icons/fa";
import Loader from "./Loader"; // Import Loader component
import '../css/form.css';

const MeterForm = () => {
  const [formData, setFormData] = useState({
    paymentType: "Electricity",
    userLocation: "",
    accountType: "Prepaid",
    MeterNumber: "", // Will be customerAccountNo for Postpaid
    phoneNumber: "",
    email: "",
    amount: "", // Will be paymentChannelAmount for Postpaid
  });

  const [electricityAmount, setElectricityAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [customerInfo, setCustomerInfo] = useState(null); // Hold verified customer info for display
  const [showVerificationModal, setShowVerificationModal] = useState(false); // Show verification modal
  const [submitted, setSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false); // State to show/hide the loader

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = parseFloat(value);
    setElectricityAmount(amount.toString());
  };

  // Handle form submission to verify customer
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setLoading(true);
  
    try {
      const verifyUrl = `https://kad-api.fyber.site/payment/verify-customer/${formData.MeterNumber}/`;
      const response = await fetch(verifyUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
  
      if (response.ok) {
        const result = await response.json();
        setCustomerInfo(result.data); // Set the nested data object to customerInfo
        setShowVerificationModal(true); // Show verification modal with customer info
      } else {
        console.error("Customer verification failed.");
      }
    } catch (error) {
      console.error("Error verifying customer:", error);
    } finally {
      setLoading(false);
    }
  };

  // Proceed to payment after confirming customer details
  const proceedToPayment = async () => {
    setShowVerificationModal(false); // Close verification modal
    setLoading(true);

    const postUrl =
      formData.accountType === "Prepaid"
        ? "https://kad-api.fyber.site/payment/init/online-pay-prepaid/"
        : "https://kad-api.fyber.site/payment/init/online-pay-postpaid/";

    const payload = formData.accountType === "Prepaid"
      ? {
          agentId: "1",
          meterNumber: formData.MeterNumber,
          amount: electricityAmount,
          locationOfPayment: formData.userLocation,
          notes: "Payment for electricity",
          telephoneNumber: formData.phoneNumber,
          laitude: "1",
          longitude: "1",
          payment_type: "web payment",
        }
      : {
          agentId: "1",
          customerAccountNo: formData.MeterNumber,
          paymentChannelAmount: electricityAmount,
          locationOfPayment: formData.userLocation,
          notes: "Payment for electricity",
          telephoneNumber: formData.phoneNumber,
          laitude: "1",
          longitude: "1",
          payment_type: "web payment",
        };

    try {
      const response = await fetch(postUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data && data.data.checkoutUrl) {
          setIframeSrc(data.data.checkoutUrl);
          setShowModal(true);
          const reference = data.data.reference;
          await pollForPaymentStatus(reference); // Start polling
        } else {
          console.error("checkoutUrl not found in response");
        }
      } else {
        console.error("Payment initiation failed.");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoading(false);
    }
  };

  const pollForPaymentStatus = async (reference) => {
    const checkInterval = 10000;
    const maxAttempts = 12;
    let attempts = 0;

    while (attempts < maxAttempts) {
      const finalizeResponse = await fetch(
        `https://kad-api.fyber.site/payment/finalize/online-pay/${reference}/${formData.MeterNumber}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (finalizeResponse.ok) {
        const finalizeData = await finalizeResponse.json();

        // Show loader and wait for 5 seconds before navigation
        setShowLoader(true); // Show loader
        setTimeout(() => {
          navigate("/Success", { state: { finalizeData, formData } }); // Navigate after 5 seconds
        }, 5000); // Delay of 5 seconds
        break;
      } else {
        const errorText = await finalizeResponse.text();
        if (errorText.includes("Payment Still Processing!")) {
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, checkInterval));
        } else {
          break;
        }
      }
    }

    if (attempts >= maxAttempts) {
      console.error("Max attempts reached for finalizing payment.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setIframeSrc("");
    setSubmitted(false);
  };

  return (
    <div className="form-container paddng_form">
     {showLoader && <Loader />} {/* Show loader 5 seconds before navigating */}
      <form className="payment-form" onSubmit={handleSubmit}>
        <h2>Payment Form</h2>
        <div className="form-group">
          <label htmlFor="accountType">Account Type</label>
          <div className="select-wrapper">
            <select
              id="accountType"
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
              disabled={loading} 
            >
              <option value="Prepaid">Prepaid</option>
              <option value="Postpaid">Postpaid</option>
            </select>
            <RiArrowDownSFill className="dropdownarrow" />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="userLocation">Location</label>
          <div className="select-wrapper">
            <select
              id="userLocation"
              name="userLocation"
              value={formData.userLocation}
              onChange={handleChange}
              required
              disabled={loading}
            >
              <option value="" disabled>Select Location</option>
              <option value="Kaduna">Kaduna</option>
              <option value="Kebbi">Kebbi</option>
              <option value="Sokoto">Sokoto</option>
              <option value="Zamfara">Zamfara</option>
            </select>
            <RiArrowDownSFill className="dropdownarrow" />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="MeterNumber">
            {formData.accountType === "Prepaid" ? "Meter Number" : "Customer Account Number"}
          </label>
          <input
            type="text"
            id="MeterNumber"
            name="MeterNumber"
            value={formData.MeterNumber}
            onChange={handleChange}
            placeholder={`Enter ${formData.accountType === "Prepaid" ? "meter number" : "customer account number"} here`}
            required
            disabled={loading} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email address"
            required
            disabled={loading} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter phone number"
            required
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label htmlFor="electricityAmount">
            {formData.accountType === "Prepaid" ? "Electricity Amount (₦)" : "Payment Channel Amount (₦)"}
          </label>
          <input
            type="number"
            id="electricityAmount"
            name="electricityAmount"
            value={electricityAmount}
            onChange={handleAmountChange}
            min="0"
            required
            disabled={loading} 
          />
        </div>
        <div className="button-container">
          <button type="submit" className="submit" disabled={loading}>
            {loading ? <FaSpinner className="spinner rotating" /> : "Verify & Proceed"}
          </button>
        </div>
      </form>

      {showVerificationModal && customerInfo && (
  <div className="modal-overlay-info">
    
    <div className="infomodal">
      <button className="exit_button" onClick={() => setShowVerificationModal(false)}>x</button>
      <h3>User Info</h3>
      <div className="info_content">
      <p><strong>Customer Name:</strong> {customerInfo.customerName || "N/A"}</p>
      <p><strong>Meter Number:</strong> {customerInfo.meterNumber || "N/A"}</p>
      <p><strong>Location:</strong> {formData.userLocation}</p>
      <p><strong>Address:</strong> {customerInfo.customerAddress || "N/A"}</p>
      <p><strong>State:</strong> {customerInfo.customerState || "N/A"}</p>
      <p><strong>Transformer:</strong> {customerInfo.customerTransformer || "N/A"}</p>
      <div className="button-container">
        <button className="submit" onClick={proceedToPayment}>
          {loading ? <FaSpinner className="spinner rotating" /> : "Proceed Payment"}
        </button>
      </div>
      </div>
    </div>
  </div>
)}


      {/* Payment Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-button" onClick={closeModal}>X</button>
            <iframe
              src={iframeSrc}
              title="Payment Checkout"
              style={{ width: "100%", height: "500px", border: "none" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeterForm;
