import React from 'react';
import "../css/Footer.css"

const Footer = () => {
  return (
    <div>
        <div className='footer'>
        <p>Powered by Blumen Technologies®,  &copy; {new Date().getFullYear()} All Rights Reserved.</p>
    </div>
    </div>
    
  );
}

export default Footer;
