import React from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { BrowserRouter } from "react-router-dom";
import Base from './routes/Base';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* Content and Navbar */}
        <div className="base_container">
          <Navbar />
          <Base />
        </div>
        
        {/* Footer */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
