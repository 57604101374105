import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";

import Register from "../Components/Auth/Register";

function RegisterRouter (props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Register />} />
    </Routes>
  );
}

export default RegisterRouter ;
