import React from 'react'

const LogInRouter = () => {
  return (
    <div className="content">
      <h2>LogInRouter</h2>
      
    </div>
  );
};

export default LogInRouter;
