import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Confirmation from "../Components/Confirmation";

function ConfirmationRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Confirmation />} />
    </Routes>
  );
}

export default ConfirmationRouter;
