import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateRandomToken } from "../utils/utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ConfirmationPage = () => {
  const location = useLocation();
  console.log(location.state); // Debugging

  // Ensure userLocation is correctly extracted from location.state
  const { userLocation } = location.state || { userLocation: "Not specified" };

  const {
    estate,
    paymentType,
    accountType,
    MeterID,
    phoneNumber,
    email,
    amount,
    price,
    cardOwner,
    cardNumber,
    expiryDate,
    cvv,
    cardType,
    numberOfUnits,
    waterAmount,
  } = location.state || {};
  const [token, setToken] = useState("");

  const [showPinModal, setShowPinModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  // Function to handle payment status timeout
  useEffect(() => {
    let timer;
    if (paymentStatus) {
      timer = setTimeout(() => {
        setPaymentStatus(null); // Reset payment status after 5 seconds
      }, 1300);
    }
    return () => clearTimeout(timer); // Clear timer on component unmount or status change
  }, [paymentStatus]);

  // Function to save the success modal as a receipt (PDF)
  const saveReceiptAsPDF = () => {
    const successModal = document.querySelector(".success_content");

    html2canvas(successModal, { scale: 4 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 180;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("receipt.pdf");
    });
  };

  // Function to save the success modal as an image (PNG)
  const saveReceiptAsImage = () => {
    const successModal = document.querySelector(".success_content");

    html2canvas(successModal, { scale: 4 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "receipt.png";
      link.click();
    });
  };

  const handleConfirm = () => {
    setShowPinModal(true);
  };

  const handlePinSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      const isValidPin = pin === "1234"; // Dummy PIN check
      setLoading(false);
      setShowPinModal(false);
      setPaymentStatus(isValidPin ? "success" : "failure");
      if (isValidPin) {
        const generatedToken = generateRandomToken();
        setToken(generatedToken); // Save the token
        setShowSuccessModal(true);
      }
    }, 2000);
  };

  const handleCancel = () => {
    setShowPinModal(false);
    setPin("");
  };

  return (
    <div className="payment section__padding">
       <div className="payment_contain ">
      {paymentStatus && (
        <div className={`payment-status ${paymentStatus}`}>
          {paymentStatus === "success"
            ? "Payment Successful!"
            : "Payment Failed! Please try again."}
        </div>
      )}
      <div className="content">
        <div className="payname">
          <h2>Confirm Payment</h2>
        </div>

        <div className="payment-summary">
          <p>
            <strong>Location:</strong> {userLocation}
          </p>
          <p>
            <strong>Payment Type:</strong> {paymentType}
          </p>
        
          {paymentType === "Electricity" ? (
            <p>
              <strong>Amount:</strong> ₦{amount}
            </p>
          ) : (
            <p>
              <strong>Price:</strong> ₦{price}
            </p>
          )}
          <p>
            <strong>Account Type:</strong> {accountType}
          </p>
          <p>
            <strong>Meter Number:</strong> {MeterID}
          </p>
          <p>
            <strong>Email:</strong> {email}
          </p>
          <p>
            <strong>Phone Number:</strong> {phoneNumber}
          </p>
          <p>
            <strong>Card Owner:</strong> {cardOwner}
          </p>
          <p>
            <strong>Card Number:</strong> {cardNumber}
          </p>
          <p>
            <strong>Expiry Date:</strong> {expiryDate}
          </p>
          <p>
            <strong>CVV:</strong> {cvv}
          </p>
          <p>
            <strong>Card Type:</strong> {cardType}
          </p>
          {paymentType === "Electricity" && (
            <p>
              <strong>Number of Units:</strong> {numberOfUnits}
            </p>
          )}
          
          
        </div>

        <div className="button-container">
          <button onClick={handleConfirm} className="submit">
            Confirm Payment
          </button>
        </div>
      </div>

      {showPinModal && (
        <div className="modal">
          <div className="modal-content-pin">
            <h2>Card PIN</h2>
            <input
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Enter PIN"
            />
            <div className="modal-buttons">
              <button onClick={handlePinSubmit} className="submit-button">
                {loading ? "Processing..." : "Pay Now"}
              </button>
              <button onClick={handleCancel} className="cancel-button">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="modal">
          <div className="success_content">
            <span className="close" onClick={() => setShowSuccessModal(false)}>
              &times;
            </span>
            <h2>Payment Successful!</h2>
            <p>
              <strong>Location:</strong> {userLocation}
            </p>
            <p>
              <strong>Payment Type:</strong> {paymentType}
            </p>
            {paymentType === "Water" && (
              <p>
                <strong>Estate:</strong> {estate}
              </p>
            )}
            {paymentType === "Electricity" ? (
              <p>
                <strong>Amount:</strong> ₦{amount}
              </p>
            ) : (
              <p>
                <strong>Price:</strong> ₦{price}
              </p>
            )}
            <p>
              <strong>Account Type:</strong> {accountType}
            </p>
            <p>
              <strong>Meter Number:</strong> {MeterID}
            </p>
            <p>
            <strong>Email:</strong> {email}
          </p>
            <p>
              <strong>Phone Number:</strong> {phoneNumber}
            </p>
            <p>
              <strong>Card Owner:</strong> {cardOwner}
            </p>
            <p>
              <strong>Card Number:</strong> {cardNumber}
            </p>
            <p>
              <strong>Expiry Date:</strong> {expiryDate}
            </p>
            <p>
              <strong>CVV:</strong> {cvv}
            </p>
            <p>
              <strong>Card Type:</strong> {cardType}
            </p>
            {paymentType === "Electricity" && (
              <p>
                <strong>Number of Units:</strong> {numberOfUnits}
              </p>
            )}
            {paymentType === "Water" && (
              <p>
                <strong>Cubic Meters:</strong> {waterAmount}
              </p>
            )}
            <p className="token_code">
              <strong>Token:</strong> <strong>{token}</strong>
            </p>
            <p className="small-text">
              <strong>Time:</strong> {new Date().toLocaleString()}
            </p>

            <button onClick={saveReceiptAsPDF} className="button_cls">
              Save as PDF
            </button>
            <button onClick={saveReceiptAsImage} className="button_cls">
              Save as Image
            </button>
          </div>
        </div>
      )}

      {loading && <div className="loader">Loading...</div>}
    </div>
    </div>
   
  );
};

export default ConfirmationPage;
