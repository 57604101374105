import React from 'react';
import '../css/Home.css'
import MeterForm from '../Components/MeterForm';
import { GiCheckedShield } from "react-icons/gi";
import { PiHandCoinsFill } from "react-icons/pi";


const Home = () => {
  return (
    <div className='main section__padding'>
      <div className='main_header'>
        <h1>Welcome to Kaduna Electric!</h1>
      </div>
      <div className='main_content'>
        <div className='main_text'>
        <p>
        Kaduna Electric provides a reliable and secure platform for easy electricity vending. 
      Our service allows you to quickly purchase prepaid electricity and manage your account 
      with ease, ensuring uninterrupted power for your home or business. We prioritize 
      convenience, efficiency, and security, making it simple to stay connected and powered 
      at all times. Thank you for choosing Kaduna Electric for your electricity needs.
     <br />
     <br />
      <span className="mission">Our Mission</span>
   
      At Kaduna Electric Vending Platform, we aim to provide seamless, convenient, and secure 
      electricity vending services, enhancing the overall customer experience. We strive to 
      bridge the gap between electricity distribution and consumer satisfaction.
      </p>
          <div className="pro">
            <div className="protection">
              <div className="pro1">
                <GiCheckedShield className="icon" />
                <div className="data">
                  <h3>
                    Buyer Protection <br /> <span>100% Guranteed</span>
                  </h3>
                </div>
              </div>
              <div className="pro2">
                <PiHandCoinsFill className="icon" />
                <div className="data">
                  <h3>
                    Hassle Free Payment <br /> <span>Fast and Convenient</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='main_form'>

            <MeterForm />
        </div>
      </div>
      
    </div>
  );
}

export default Home;
