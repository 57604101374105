export const generateRandomToken = () => {
    let token = '';
    for (let i = 0; i < 16; i++) {
      token += Math.floor(Math.random() * 10);
      if ((i + 1) % 4 === 0 && i !== 15) {
        token += ' '; // Insert space after every four digits except for the last group
      }
    }
    return token;
  };
  