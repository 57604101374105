import React from "react";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js"; // Import html2pdf for PDF saving
import '../css/success.css';
import logo from '../assets/log.png';

const Success = () => {
  const location = useLocation();
  const { finalizeData, formData } = location.state || {};

  // Function to save the receipt as PDF
  const handleSaveAsPDF = () => {
    const element = document.getElementById("receipt");
    const options = {
      margin: 0.5,
      filename: `payment_receipt_${finalizeData?.transactionId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(options).save();
  };

  // Function to share the receipt using the Web Share API
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Payment Receipt",
          text: `Here is my payment receipt for ${formData?.accountType === "Prepaid" ? `Meter Number: ${formData?.MeterNumber}` : `Customer Account Number: ${formData?.MeterNumber}`}`,
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      alert("Share feature is not supported on your browser.");
    }
  };

  return (
    <div className="container">
      <div className="receipt-container" id="receipt">
        {finalizeData && (
          <div className="receipt-content">
            <div className="title-container">
              
              <img src={logo} alt="Logo" className="logo" />
            </div>

          

            {/* Conditionally Rendered Fields for Prepaid */}
            {formData?.accountType === "Prepaid" && (
              <>
               <div className="amount">
                <span className=""> ₦{finalizeData.amount} </span>
                </div>
                <div className="text_message">
                  <span className="message">Transaction Successful</span>
                  <span className="date">{finalizeData.pub_date}</span>
                </div>
                
               <p className="token"><strong>Token: </strong> <strong>{finalizeData.result}</strong></p>
                <p><strong>Meter Number: </strong> {formData?.MeterNumber}</p>
                <p><strong>Amount: </strong> ₦{finalizeData.amount}</p>
                <p><strong>Total Unit Vended: </strong> {finalizeData.totalUnitVended}</p>
                <p><strong>Tariff Rate: </strong> {finalizeData.tariffRate}</p>
                <p><strong>VAT: </strong> {finalizeData.vat}</p>
              </>
            )}

            {/* Conditionally Rendered Fields for Postpaid */}
            {formData?.accountType === "Postpaid" && (
              <>
              <div className="amount">
                <span className=""> ₦{finalizeData.paymentChannelAmount} </span>
                </div>
                <div className="text_message">
                  <span className="message">Transaction Successful</span>
                  <span className="date">{finalizeData.pub_date}</span>
                </div>
              
              
                <p><strong>Customer Account No: </strong> {formData?.MeterNumber}</p>
                
                <p><strong>Mode Of Payment: </strong> {finalizeData.modeOfPayment}</p>
                <p><strong>Type of Payment: </strong> {finalizeData.typesOfPayment}</p>
                <p><strong>Amount: </strong> ₦{finalizeData.paymentChannelAmount}</p>
                <p className="small-text"><strong>Customer Address: </strong> {finalizeData.customerAddress}</p>
                <p><strong>Customer Transformer: </strong> {finalizeData.customerTransformer}</p>
              </>
            )}
              {/* Common Fields */}
              <p><strong>Location Of Payment: </strong> {finalizeData.locationOfPayment}</p>
              <p className="small-text"><strong>Transaction ID: </strong> {finalizeData.id}</p>
              <p><strong>Customer Name: </strong> {finalizeData.customerName}</p>
              <p><strong>Email: </strong> {formData?.email}</p>
              <p><strong>Phone Number: </strong> {finalizeData.telephoneNumber}</p>
              <p><strong>Reference: </strong> {finalizeData.reference}</p>
            

            
          </div>
        )}
           
      </div>

      <div className="button-container">
        <button className="action-button" onClick={handleSaveAsPDF}>
          Save as PDF
        </button>
        <button className="action-button" onClick={handleShare}>
          Share
        </button>
      </div>
    </div>
  );
};

export default Success;
