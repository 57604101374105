import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import creditCardType from "credit-card-type";
import visaLogo from "../assets/card/visa.png";
import mastercardLogo from "../assets/card/mastercard.png";
import verveLogo from "../assets/card/verve.png";

const CardPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    userLocation,
    estate,
    paymentType,
    accountType,
    meterNumber,
    email,
    phoneNumber,
    amount,
    price,
    numberOfUnits,
    waterAmount,
  } = location.state || {};

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [maskedCvv, setMaskedCvv] = useState("");
  const [cardType, setCardType] = useState("");
  const [showCardType, setShowCardType] = useState(false);

  const formatCardNumber = (value) => {
    return value
      .replace(/\s?/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  const handleCardNumberChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatCardNumber(value);
    setCardNumber(formattedValue);

    const cleanValue = formattedValue.replace(/\s/g, "");
    if (cleanValue.length >= 4) {
      const firstFourDigits = cleanValue.slice(0, 4);
      const cardTypeResult = creditCardType(firstFourDigits);
      if (cardTypeResult.length > 0) {
        setCardType(cardTypeResult[0].type);
        setShowCardType(true);
      } else {
        setCardType("");
        setShowCardType(false);
      }
    } else {
      setShowCardType(false);
    }
  };

  const handleExpiryDateChange = (e) => {
    const { value } = e.target;
    if (value.length === 2 && expiryDate.length === 1) {
      setExpiryDate(value + "/");
    } else if (value.length <= 5) {
      setExpiryDate(value);
    }
  };

  const handleCvvChange = (e) => {
    const { value } = e.target;
    if (value.length <= 3) {
      setCvv(value);
      setMaskedCvv("*".repeat(value.length));
    }
  };

  const handleCardPayment = (e) => {
    e.preventDefault();
    // Validate card details here
    // Implement card payment processing logic here

    // Create a masked version of the card number
    const maskedCardNumber = cardNumber.replace(/.(?=.{4})/g, "*"); // Replaces all characters except the last four with "*"

    navigate("/confirmation", {
      state: {
        userLocation,
        estate,
        paymentType,
        accountType,
        meterNumber,
        phoneNumber,
        email,
        amount,
        price,
        cardOwner: "John Doe",
        cardNumber: maskedCardNumber, // Pass the masked card number
        expiryDate,
        cvv,
        cardType,
        numberOfUnits,
        waterAmount,
      },
    });
  };

  const renderCardTypeLogo = () => {
    switch (cardType) {
      case "visa":
        return visaLogo;
      case "mastercard":
        return mastercardLogo;
      case "american-express":
        return verveLogo;
      // Add cases for other card types as needed
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleCardPayment}>
      <h2>Enter Card Details</h2>
      <div className="form-group">
        <label htmlFor="cardNumber">Card Number</label>
        <div className="card-number-field">
          <input
            type="text"
            id="cardNumber"
            name="cardNumber"
            value={cardNumber}
            onChange={handleCardNumberChange}
            required
            maxLength="19" // 16 digits + 3 spaces
          />
          {showCardType && (
            <img
              src={renderCardTypeLogo()}
              alt={cardType}
              className="card-type-logo"
            />
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="expiryDate">Expiry Date</label>
        <input
          type="text"
          id="expiryDate"
          name="expiryDate"
          value={expiryDate}
          onChange={handleExpiryDateChange}
          placeholder="MM/YY"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="cvv">CVV</label>
        <input
          type="password"
          id="cvv"
          name="cvv"
          value={maskedCvv}
          onChange={handleCvvChange}
          required
        />
      </div>
      <div className="button-container">
        <button type="submit" className="submit">
          Submit Payment
        </button>
      </div>
    </form>
  );
};

export default CardPayment;
