import React from "react";
import { FaSpinner } from "react-icons/fa"; // Spinner icon
import "../css/Loader.css"

const Loader = () => {
  return (
    <div className="loader-container">
      <FaSpinner className="spinner rotating large-spinner" />
    </div>
  );
};

export default Loader;
