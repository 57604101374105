import React, { useState } from 'react';
import "../css/pages.css"
import { useLocation } from 'react-router-dom';
import CardPaymentModal from '../Components/CardPaymentModal'; // Assuming you have a CardPaymentModal component

const Payment = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const { location:   userLocation, paymentType, accountType, MeterID, phoneNumber, amount, email, numberOfUnits, waterAmount } = location.state || {};

  const [showModal, setShowModal] = useState(false);

  const handlePaymentOption = (option) => {
    console.log(`Payment option selected: ${option}`);
    if (option === 'card') {
      setShowModal(true);
    } else {
      // Implement transfer payment processing logic here
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='payment section__padding'>
        <div className='pay_Sec'>
        <div className='payment_contain section__margin'>
      <div className="content">
        <div className='payname'><h2>Payment Page</h2></div>
        <div className="payment-summary">
          <p><strong>Location:</strong> {userLocation}</p>
          <p><strong>Payment Type:</strong> {paymentType}</p>
          <p><strong>Amount:</strong> ₦{amount}</p>
          <p><strong>Account Type:</strong> {accountType}</p>
          <p><strong>Meter Number:</strong> {MeterID}</p>
          <p><strong>Email:</strong> {email}</p>
          <p><strong>Phone Number:</strong> {phoneNumber}</p>
           <p><strong>Number of Units: </strong>{numberOfUnits}</p>
          
        </div>
        <div className="button-container">
          <button onClick={() => handlePaymentOption('transfer')} className='cardbtn'>Pay with Transfer</button>
          <button onClick={() => handlePaymentOption('card')} className='cardbtn'>Pay with Card</button>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <CardPaymentModal
              userLocation={userLocation}
              
              paymentType={paymentType}
              accountType={accountType}
              meterNumber={MeterID}
              email={email}
              phoneNumber={phoneNumber}
              amount={amount}
              
            />
          </div>
        </div>
      )}
    </div>
        </div>
        
    </div>
    
  );
};

export default Payment;
