

export const paths = Object.freeze({
  base: "/",
  login: "/login",
  register: "/register",
  payment: "/payment/*",
  confirmation: "/confirmation",
  success: "/success",
  
  
});